import React from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { fullDisplayNameForAPICategory } from "components/docs/utils/services";
import { APICategory } from "@merge-api/merge-javascript-shared";
import DeprecatedH1 from "components/deprecated/DeprecatedH1";

const IntegrationSandboxPageContainer = styled(Row)`
  && {
    @media (max-width: 767.96px) {
      padding: 40px 4px;
    }
    padding: 40px;
  }
`;

const IntegrationSandboxOverviewPageTitle = styled(DeprecatedH1)`
  font-size: 32px;
  font-weight: 500px;
  line-height: 400px;
  color: #080808;
`;

export type IntegrationInfo = {
  slug: string;
  square_image: string;
  name: string;
};

export type Integration = {
  integration: IntegrationInfo;
};

interface IntegrationsSandboxPageProps {
  pageContext: {
    key: APICategory;
    integrations: {
      integration: Integration;
    };
  };
}

const IntegrationsSandboxPage = ({ pageContext }: IntegrationsSandboxPageProps) => {
  const { key } = pageContext;
  const categoryDisplayName = fullDisplayNameForAPICategory(key);
  const configurationUrl = `https://app.merge.dev/integrations/${key}`;

  return (
    <IntegrationSandboxPageContainer className="w-100 d-flex flex-column mx-auto">
      <IntegrationSandboxOverviewPageTitle>Sandboxes</IntegrationSandboxOverviewPageTitle>
      {categoryDisplayName == "Ticketing" ? (
        <>
          <div className="mb-6">
            <p>
              Merge does not have sandboxes for Ticketing platforms as all providers have free
              trials.{" "}
            </p>
            <p>
              Click through the integrations in the{" "}
              <a href={configurationUrl} target="_blank">
                Configuration
              </a>{" "}
              page to find links to each provider’s free trial to test Merge’s functionality as a
              Proof of Concept.{" "}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="mb-6">
            <p>
              Merge offers sandbox access for some integrations to customers on our{" "}
              <b>Professional</b> and <b>Enterprise</b> Plans. If you’re interested in testing with
              Merge-owned sandboxes populated with sample data, please fill out this{" "}
              <a href="https://mergeapi.typeform.com/sandboxes" target="_blank">
                form
              </a>{" "}
              to request access.{" "}
            </p>
            <p>
              Some providers also offer free trials to test Merge’s functionality as a Proof of
              Concept. Click through the integrations in the{" "}
              <a href={configurationUrl} target="_blank">
                Configuration
              </a>{" "}
              page to see which integrations offer free trials.{" "}
            </p>
          </div>
        </>
      )}
    </IntegrationSandboxPageContainer>
  );
};
export default IntegrationsSandboxPage;
